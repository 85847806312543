import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Route, Switch, useHistory } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

//imports

import Home from "./Components/Home";
import Home1 from "./Components/Home1";
import Health from "./Components/Health";

//okta
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import Redirect from "./Components/Redirect";
import Ssogoogle from "./Components/Ssogoogle"
import RedirectCode from "./Components/RedirectCode";


function App() {
  const {REACT_APP_ISSUER,REACT_APP_CLIENT_ID, REACT_APP_APPLICATION_ID} = process?.env
  const company_id = REACT_APP_APPLICATION_ID;
  const history1 = useHistory();
  

  const issuer = REACT_APP_ISSUER
  const clientId = REACT_APP_CLIENT_ID


  const onAuthRequired = () => {
    history1.push("/home");
  };

  const oktaAuth = new OktaAuth({
    issuer:issuer,
    clientId: clientId,
    onAuthRequired: onAuthRequired,
    pkce: true
  });
  
  
  const restoreOriginalUri = async (_oktaAuth) => {
    // history1.replace(toRelativeUrl('/home', window.location.origin));
   if(window.location.pathname.startsWith("/sociallogin")){
     window.location.href = window.location.origin + "/social/home"
   }else{
    window.location.href = window.location.origin + "/home";
   }
  };

  return (
    <BrowserRouter>
      <Switch>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Route exact path="/">
            <Home Id={company_id} />
          </Route>
          <Route path="/home">
            <Home1 Id={company_id} />
          </Route>
          <Route path="/social/home">
            <Home1 Id={company_id} />
          </Route>
          <Route exact path="/health">
            <Health />
          </Route>
          <Route path="/redirect">
            <Redirect Id={company_id} />
          </Route>
          <Route path="/ssopage" component={Ssogoogle} />
          <Route path="/login/code" component={RedirectCode} />
          <Route path="/login/callback" component={LoginCallback} />
          <Route path="/sociallogin/code" component={RedirectCode} />
          <Route path="/sociallogin/callback" component={LoginCallback} />
          {/* <Route path="/login/callback" component={CallbackToken}/>  */}
        </Security>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
