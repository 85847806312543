import React from "react";
import "./home.css";
import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";
import {getEnv} from '../url'
import { useLocation } from "react-router";
import { useEffect } from "react";

const Home = ({ Id }) => {
  const { oktaAuth } = useOktaAuth();
  const [dropdownItem, setDropdownItem] = useState("en");
  const {REACT_APP_REDIRECT_URI} = process.env
  const RedirectURL= REACT_APP_REDIRECT_URI
  
  const id = Id;
  const handleSelectChange = (e) => {
    const { value } = e.target;
    setDropdownItem(value);
  };

  
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  let data = query.get("data")

  useEffect(() =>{
   if(data){
    alert("Event Triggered Successfully")
   }
   //eslint-disable-next-line
  },[])


  const createAccount = () => {
    window.location.href = `${getEnv().url}create-account/${id}?languageCode=${dropdownItem}&redirectUrl=${window.location.origin}`;
  };

  const forgotPassword = () => {
    window.location.href = `${getEnv().url}forgotpassword/${id}?languageCode=${dropdownItem}`;
  };
  

  // const CreateAccountOther = () => {
  //   window.location.href = `${REACT_APP_UI_URL2}create-account/${id}?LanguageCode=${dropdownItem1}`;
  // };

  // const forgotPasswordOther = () => {
  //   window.location.href = `${REACT_APP_UI_URL2}forgotpassword/${id}?LanguageCode=${dropdownItem1}`;
  // };

  const RouteGoogle = () => {
    // window.location.href = `http://${decodedString}/`;
    oktaAuth.signInWithRedirect({
      idp: "0oa14djekuzRqQJeJ0h8",
      responseType: "code",
    });
  };
  const loginHint_Data = {
    "AppId" : id,
    "languageCode": dropdownItem,       
  }

  console.log({id})

 
   
  return (
    <>
      <div className="home-page"> 
        <button
          className="btn btn-primary"
          // onClick={() => postmethod1(id, encodedString)}
          onClick={() =>{
            oktaAuth.signInWithRedirect({
              loginHint: JSON.stringify(loginHint_Data),
              scopes: ["openid", "offline_access"],
              redirectUri:`${RedirectURL}/login/callback`
            })
          } 
          }
        >
          Login
        </button>
        <button className="btn btn-dark" onClick={createAccount}>
          Create Account
        </button>

        <button className="btn btn-danger" onClick={forgotPassword}>
          Forgot Password
        </button>

        <button className="btn btn-success" onClick={RouteGoogle}>
          Social Login
        </button>

        <select
          className="btn-lg btn-primary "
          aria-label=".form-select-lg example"
          onChange={handleSelectChange}
        >
          <option value="en">English</option>
          <option value="hi">Hindi</option>
          <option value="fr">French</option>
          <option value="es">Spanish</option>
        </select>
      </div>
    </>
  );
};

export default Home;
