import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import {useLocation} from 'react-router-dom'

import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: "red",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      height: "100%",
    },
  })
);

function Redirect({Id}) {

  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const {REACT_APP_REDIRECT_URI} = process?.env
  const RedirectURL= REACT_APP_REDIRECT_URI
  
  let query = useQuery();
  // const LanguageCode = query.get("LanguageCode")
  // const DynamicId = query.get("AppId")
  const loginHint_Data = {
    "AppId" : Id,
    "LanguageCode": 'en',       
  }
  
  

  useEffect(() => {
    if(query.get("SignOutFlag")){
      oktaAuth.signOut()
    }else{
      console.log("ENTERED1", loginHint_Data)
      oktaAuth.signInWithRedirect({ loginHint: JSON.stringify(loginHint_Data),
      scopes: ["openid", "offline_access"],
      redirectUri: `${RedirectURL}/login/callback`
    });
    }
    //eslint-disable-next-line
  }, []);


  return (
    <div>
      <Backdrop className={classes.root} sx={{ color: "#fff" }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  );
}

export default Redirect;
