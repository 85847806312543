import React from "react";

const StyleDiv = {
  margin: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "30px",
};

function Health() {
  const health_obj = {
    Status: "Health",
    Description: "App Health Is Good.",
  };
  return (
    <div style={StyleDiv}>
      <b>{JSON.stringify(health_obj, null, 2)}</b>
    </div>
  );
}

export default Health;
