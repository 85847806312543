import React, { useEffect } from 'react';
import {useOktaAuth} from "@okta/okta-react"
function RedirectCode() {
    const {oktaAuth} = useOktaAuth()
    const {REACT_APP_REDIRECT_URI} = process.env
    const RedirectURL= REACT_APP_REDIRECT_URI;
    let Redirect
    if(window.location.pathname.startsWith('/sociallogin')){
      Redirect = `${RedirectURL}/sociallogin/callback`
    }else{
      Redirect = `${RedirectURL}/login/callback`
    }
    useEffect( () => {
        oktaAuth.signInWithRedirect({
            idp: "0oa14djekuzRqQJeJ0h8",
            responseType: "code",
            originalUri: "/",
            redirectUri: Redirect
          });
          //eslint-disable-next-line
    }, [])
  return <div></div>;
}

export default RedirectCode;
