export const getEnv = () =>{
    if( process.env.REACT_APP_UI_URL_1 === "testapp.consumer.ite.secure.pepsico.com"){
        return {
            url: 'https://consumer.ite.secure.pepsico.com/'
        }
    }else if(process.env.REACT_APP_UI_URL_1 === "secure-testapp-consumer.pepsico.com"){
        return {
            url: 'https://www.secure-consumer.pepsico.com/'
        }
    }
    else if(process.env.REACT_APP_UI_URL_2 === window.location.hostname){
        return {
            url: 'https://consumer-02.ite.secure.pepsico.com/'
        }

    }else{
        return{
            url: 'http:localhost:3000/'
        }
    }
}



