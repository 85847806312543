import { useOktaAuth } from '@okta/okta-react'
import React from 'react'

function Ssogoogle() {
    const {oktaAuth} = useOktaAuth()
    const {REACT_APP_IDP} = process.env
    const idpId = REACT_APP_IDP
    return (
        <div className="btn btn-warning m-5">
            <button onClick={() => oktaAuth.signInWithRedirect({idp:idpId, responseType:'code'}) }>
                Login Via Google
            </button>
        </div>
    )
}

export default Ssogoogle
