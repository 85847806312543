import React, {useEffect} from "react";
import { useOktaAuth } from "@okta/okta-react";
import {getEnv} from '../url'


function Home1({ Id }) {
  const { oktaAuth } = useOktaAuth();
  const id = Id;
  const langCode = 'en'

  

  const resData = JSON.parse(localStorage.getItem("okta-token-storage"));
  const AccessToken = resData.accessToken.accessToken;
  const UserID = resData.accessToken.claims.uid;
  // const userName = resData.accessToken.claims.sub
  const refreshToken = async() => {
    if(AccessToken){
      await oktaAuth.tokenManager.renew('idToken')
      .then( function(newToken){
         console.log(newToken)
      })
   }
     
  }

  const Changepassword = () => {
    window.location.href = `${getEnv().url}changepassword/${id}/${UserID}?token=${AccessToken}&languageCode=${langCode}`;
  };

  const ChangeProfile = () => {
    window.location.href = `${getEnv().url}update-profile/${id}/${UserID}?token=${AccessToken}&languageCode=${langCode}`;
  };

  const Logout = async () => {
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    await oktaAuth.signOut();
  };

  useEffect( () => { 
    if(window.location.pathname.startsWith("/social")){
      window.location.href = `${getEnv().url}update-profile/${id}/${UserID}?token=${AccessToken}&social=true&languageCode=${langCode}`;
    }
    //eslint-disable-next-line
  }, [AccessToken])
 
useEffect(() => {
  alert("Event Triggered Successfull");
},[])
  
  return (
    <div>
      <h1>Welcome Consumer App 1</h1>
      <p>ACCESSTOKEN: {AccessToken}</p>
      <p>USERID: {UserID}</p>
      <button
        style={{
          color: "#000",
          backgroundColor: "#ffc107",
          borderColor: "#ffc107",
          margin: "10px",
          padding: "10px",
          borderRadius: "10px",
        }}
        onClick={refreshToken}
      >
        Refresh Token
      </button>
      <button
        style={{
          color: "#000",
          backgroundColor: "#ffc107",
          borderColor: "#ffc107",
          margin: "10px",
          padding: "10px",
          borderRadius: "10px",
        }}
        onClick={Changepassword}
      >
        Change Password
      </button>

      <button
        style={{
          color: "#000",
          backgroundColor: "#ffc107",
          borderColor: "#ffc107",
          margin: "10px",
          padding: "10px",
          borderRadius: "10px",
        }}
        onClick={ChangeProfile}
      >
        Update Profile
      </button>
      <button
        style={{
          color: "red",
          backgroundColor: "#ffc107",
          borderColor: "#ffc107",
          margin: "10px",
          padding: "10px",
          borderRadius: "10px",
        }}
        onClick={Logout}
      >
        Log Out
      </button>
    </div>
  );
}

export default Home1;
